import HttpRequest from '../HttpRequest';

const get = async (orderNo) => {
  const response = await HttpRequest.get('/api/order/get', { orderNo });
  return response;
};

const getUserOrder = async () => {
  const response = await HttpRequest.get('/api/order/getUserOrder');
  return response;
};

const getOrderPayment = async (orderNo) => {
  const response = await HttpRequest.get('/api/order/getOrderPayment', { orderNo });
  return response;
};

const getHistory = async (userId) => {
  const response = await HttpRequest.get('/api/order/getHistory', { userId });
  return response;
};

const generateOrderNo = async (values) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  const response = await HttpRequest.post('/api/order/generateOrderNo', {},headers , JSON.stringify(values));
  return response;
};

const payConfirm = async (values) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  const response = await HttpRequest.post('/api/order/pay_confirm', {},headers , JSON.stringify(values));
  return response;
};

const orderFreeTrialProduct = async (values) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  const response = await HttpRequest.post('/api/order/orderFreeTrial', {},headers , JSON.stringify(values));
  return response;
};

const getAllProducts = async () => {
  const response = await HttpRequest.get('/api/product/list');
  return response;
};

export default {
  get,
  getUserOrder,
  getOrderPayment,
  getHistory,
  generateOrderNo,
  payConfirm,
  orderFreeTrialProduct,
  getAllProducts,
};

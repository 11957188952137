import { useContext, createContext, useState, useCallback, useMemo, useEffect } from 'react';
import { Box, RowCenter, Text } from 'styles';
import { Modal, Button } from 'components';

const AlertContext = createContext({
  alert: () => {},
  toggleAlert: () => {}
});

export function AlertContextProvider({ children }) {
  const [isConfirm, setIsConfirm] = useState(false);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({
    title: 'Info',
    message: '',
    confirm: () => {},
    cancel: () => {}
  });

  const alert = useCallback((alertData) => {
    toggleAlert(true);
    const closeAlert = () => toggleAlert(false);

    if (alertData.confirm) {
      setIsConfirm(true);
    } else {
      setIsConfirm(false);
    }

    setData({ ...alertData, confirm: alertData.confirm || closeAlert, cancel: alertData.cancel || closeAlert });
  }, []);

  const toggleAlert = useCallback((value) => {
    setOpen((prevState) => value ?? !prevState);
  }, []);

  const value = useMemo(() => {
    const val = {
      alert,
      toggleAlert
    };

    return val;
  }, [alert, toggleAlert]);

  useEffect(() => {
    if (!open) {
      if (data.onClose && typeof data.onClose === 'function') data.onClose();
    }
  }, [open]);

  return (
    <AlertContext.Provider value={value}>
      {children}
      <Modal open={open} title={data.title} setOpen={() => setOpen(false)}>
        <Box $width={516} $tWidth="100%">
          <Text dangerouslySetInnerHTML={{ __html: data.message }} />
          <RowCenter $gap={16} $paddingTop={28}>
            <Button $type="12" $width={134} $tWidth="100%" $height={36} onClick={() => data.confirm()}>
              OK
            </Button>
            {isConfirm && (
              <Button mode="line" $type="12" $width={95} $tWidth="100%" $height={36} $tOrder="-1" onClick={() => data.cancel()}>
                CANCEL
              </Button>
            )}
          </RowCenter>
        </Box>
      </Modal>
    </AlertContext.Provider>
  );
}

export function useAlertContext() {
  return useContext(AlertContext);
}

export default useAlertContext;
